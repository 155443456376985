import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ThankYou from "../views/ThankYou.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/law-enforcement-loss-of-life",
    name: "LawEnforcement",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "LawEnforcement" */ "../views/LawEnforcement.vue"
      )
  },
  {
    path: "/non-lethal-alternatives",
    name: "NonLethalAlternatives",
    component: () =>
      import(
        /* webpackChunkName: "NonLethalAlternatives" */ "../views/NonLethalAlternatives.vue"
      )
  },
  {
    path: "/non-lethal-manufacturers",
    name: "NonLethalManufacturers",
    component: () =>
      import(
        /* webpackChunkName: "NonLethalManufacturers" */ "../views/NonLethalManufacturers.vue"
      )
  },
  {
    path: "/about-non-lethal-group",
    name: "AboutNonLethalGroup",
    component: () =>
      import(
        /* webpackChunkName: "AboutNonLethalGroup" */ "../views/AboutNonLethalGroup.vue"
      )
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: ThankYou
  },
  {
    path: "/non-lethal-news",
    name: "NonLethalNews",
    component: () =>
      import(
        /* webpackChunkName: "NonLethalNews" */ "../views/NonLethalNews.vue"
      )
  },
  {
    path: "/non-lethal-news/:title",
    name: "NonLethalNewsArticle",
    component: () =>
      import(
        /* webpackChunkName: "NonLethalNewsArticle" */ "../views/NonLethalNewsArticle.vue"
      )
  },
  {
    path: "/support-non-lethal-group",
    name: "SupportNonLethalGroup",
    component: () =>
      import(
        /* webpackChunkName: "SupportNonLethalGroup" */ "../views/SupportNonLethalGroup.vue"
      )
  },
  {
    path: "/encyclopedia-non-lethal-technologies",
    name: "Encyclopedia",
    component: () =>
      import(
        /* webpackChunkName: "Encyclopedia" */ "../views/Encyclopedia/Index.vue"
      )
  },
  {
    path: "/encyclopedia-non-lethal-technologies/barriers",
    name: "Barriers",
    component: () =>
      import(
        /* webpackChunkName: "Barriers" */ "../views/Encyclopedia/Barriers.vue"
      )
  },
  {
    path: "/encyclopedia-non-lethal-technologies/blunt-force",
    name: "Blunt Force",
    component: () =>
      import(
        /* webpackChunkName: "BluntForce" */ "../views/Encyclopedia/BluntForce.vue"
      )
  },
  {
    path: "/encyclopedia-non-lethal-technologies/chemical-irritants",
    name: "Chemical Irritants",
    component: () =>
      import(
        /* webpackChunkName: "ChemicalIrritants" */ "../views/Encyclopedia/ChemicalIrritants.vue"
      )
  },
  {
    path: "/encyclopedia-non-lethal-technologies/directed-energy-devices",
    name: "Directed Energy Devices",
    component: () =>
      import(
        /* webpackChunkName: "DirectedEnergyDevices" */ "../views/Encyclopedia/DirectedEnergyDevices.vue"
      )
  },
  {
    path: "/encyclopedia-non-lethal-technologies/distraction-devices",
    name: "Distraction Devices",
    component: () =>
      import(
        /* webpackChunkName: "DistractionDevices" */ "../views/Encyclopedia/DistractionDevices.vue"
      )
  },
  {
    path: "/encyclopedia-non-lethal-technologies/conducted-energy-devices",
    name: "Conducted Energy Devices",
    component: () =>
      import(
        /* webpackChunkName: "ConductedEnergyDevices" */ "../views/Encyclopedia/ConductedEnergyDevices.vue"
      )
  },
  {
    path: "/encyclopedia-non-lethal-technologies/psychoactive-chemicals",
    name: "Psychoactive Chemicals",
    component: () =>
      import(
        /* webpackChunkName: "PsychoactiveChemicals" */ "../views/Encyclopedia/PsychoactiveChemicals.vue"
      )
  }
];
/* eslint-disable */
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return {
        x: 0,
        y: 0
      }
    }
    
  }
});
/* eslint-enable */

export default router;
