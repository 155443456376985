var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"content-1-colz"},[_c('div',{staticClass:"colz"},[_c('div',{staticClass:"card-wrapper"},_vm._l((_vm.recentNews),function(article){return _c('div',{key:article.title,staticClass:"card"},[_c('router-link',{attrs:{"to":{
              name: 'NonLethalNewsArticle',
              params: { title: article.title },
            }}},[_c('div',{staticClass:"image-wrapper",style:({
                backgroundImage: `url(${article.image})`,
                backgroundSize: 'cover',
              })},[_c('div',{staticClass:"dateRibbon",domProps:{"innerHTML":_vm._s(_vm.parseDate(article.date))}})]),_c('div',{staticClass:"caption-wrapper"},[_c('div',{staticClass:"source"},[_vm._v(_vm._s(article.source))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(article.title))])])])],1)}),0)])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/"}},[_c('h4',{staticClass:"logo"},[_vm._v(" NON "),_c('span',[_vm._v("LETHAL")]),_vm._v("GROUP ")])]),_vm._m(1)],1),_c('div',{staticClass:"col"},[_c('h4',[_vm._v("RESOURCES")]),_c('p',[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/encyclopedia-non-lethal-technologies"}},[_vm._v("ENCYCLOPEDIA OF NON LETHAL"),_c('br'),_vm._v("TECHNOLOGIES")])],1)])])]),_c('div',{staticClass:"col"},[_c('h4',[_vm._v("HOW YOU CAN HELP")]),_c('p',[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/about-non-lethal-group#support"}},[_vm._v("Support")])],1)])])]),_c('div',{staticClass:"col"},[_c('h4',[_vm._v("SPREAD THE WORD")]),_vm._m(2),_c('vue-goodshare-facebook',{attrs:{"page_url":_vm.referralUrl,"has_icon":""}}),_c('vue-goodshare-twitter',{attrs:{"page_url":_vm.referralUrl,"has_icon":""}}),_c('vue-goodshare-reddit',{attrs:{"page_url":_vm.referralUrl,"has_icon":""}}),_c('vue-goodshare-linked-in',{attrs:{"page_url":_vm.referralUrl,"has_icon":""}}),_c('vue-goodshare-email',{staticStyle:{"background-color":"#dd0031"},attrs:{"page_url":_vm.referralUrl,"has_icon":""}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"containerz"},[_c('div',{staticClass:"colz recent-headlines",staticStyle:{"text-align":"left","padding":"15px","margin-left":"15px"}},[_c('h4',[_vm._v("RECENT HEADLINES")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("We are exploring effective non-lethal"),_c('br'),_vm._v("alternatives for law enforcement.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Please join our community: share your thoughts, stories and hopes with us. We are stronger when we are working together. "),_c('br'),_vm._v(" Let your friends know about The Non Lethal Group:")])
}]

export { render, staticRenderFns }