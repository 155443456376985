<template>
  <div class="home">
    <!-- <nav id="cd-vertical-nav" class="ui-style">
      <ul>
        <li>
          <a href="#introduction" data-number="1">
            <span class="cd-dot"></span>
            <span class="cd-label">Introduction</span>
          </a>
        </li>
        <li>
          <a href="#section_two" data-number="2">
            <span class="cd-dot"></span>
            <span class="cd-label">Alternatives</span>
          </a>
        </li>
        <li>
          <a href="#section_three" data-number="3">
            <span class="cd-dot"></span>
            <span class="cd-label">Police</span>
          </a>
        </li>
        <li>
          <a href="#section_four" data-number="4">
            <span class="cd-dot"></span>
            <span class="cd-label">Details</span>
          </a>
        </li>
      </ul>
    </nav>-->
    <section id="introduction" class="section">
      <h2>
        The power to
        <a href="#section_two"> <span>POLICE</span> </a>, not the power to
        <a href="#section_two"> <span>KILL</span> </a>.
      </h2>
    </section>
    <section id="section_two" class="section">
      <h3 class="headline">
        The trend of fatal police shootings in the United States seems to only
        be increasing, with a total 506 civilians having been shot as of June
        30, 2020.
        <span>Statista Research Department, STATISTA.COM</span>
      </h3>
      <div class="chart">
        <WaPoChart :btData="chartData" />
        <label for="race">
          Race
          <select name="race" id="race" v-model="wapoRace">
            <option value selected>All</option>
            <option
              v-for="race in races"
              :value="race.value"
              :key="race.value"
              >{{ race.label }}</option
            >
          </select>
        </label>
        <label for="gender">
          Gender
          <select name="gender" id="gender" v-model="wapoGender">
            <option value selected>All</option>
            <option
              v-for="gender in genders"
              :value="gender.value"
              :key="gender.value"
              >{{ gender.label }}</option
            >
          </select>
        </label>
        <p>Total Deaths: {{ analyticsData.length }}</p>
        <p>
          Data obtained from the Washington Post
          <a href="https://github.com/washingtonpost/data-police-shootings"
            >database of every fatal police shooting</a
          >
        </p>
      </div>

      <h2>
        We are exploring effective
        <!-- <a href="#section_three"> -->
        <router-link to="/non-lethal-alternatives"
          ><span>non-lethal</span></router-link
        >
        <!-- </a> -->
        alternatives for
        <a href="#section_three"> <span>law enforcement</span> </a>.
      </h2>
    </section>
    <section id="section_three" class="section">
      <h3 class="headline">
        Since 2000, the amount of police killings has increased every year.
        <span>WASHINGTON POST</span>
      </h3>
      <img
        src="/static/images/handcuffs.svg"
        class="handcuffs"
        alt="Police killings are on the rise"
      />
      <h2>
        We recognize the need for
        <a href="#blunt_force"> <span>law enforcement</span> </a>, along with
        the need for
        <router-link to="/non-lethal-manufacturers"
          ><span>non-lethal tools</span></router-link
        >
        and
        <a href="#blunt_force"> <span>techniques</span> </a>.
      </h2>
    </section>
    <section id="blunt_force" class="section">
      <h2>
        The power to
        <a href="#encyclopedia_carousel"> <span>POLICE</span> </a>, not the
        power to <a href="#encyclopedia_carousel"> <span>KILL</span> </a>.
      </h2>
    </section>
    <section id="encyclopedia_carousel" class="section">
      <router-link
        to="/encyclopedia-non-lethal-technologies/"
        class="intro-link"
        ><img
          src="@/assets/images/ico_book.png"
          alt="encyclopedia icon"
          class="ico"
        /><br />An Encyclopedia of Non-Lethal Technologies</router-link
      >
      <p>
        In an effort to better understand non-lethal tools and technologies,
        we've prepared an introductory encyclopedia of the most popular
        alternatives to lethal force. We examine the history of a broad range of
        non-lethal technologies, along with their pros, cons and suitability for
        modern law enforcement.
      </p>
      <EncyclopediaCarousel />
      <!-- <h2>
        The power to
        <a href="#section_four">
          <span>POLICE</span> </a
        >, not the power to
        <a href="#section_four">
          <span>KILL</span> </a
        >.
      </h2> -->
    </section>
    <section id="section_four" class="section">
      <div class="col">
        <img
          src="@/assets/images/svg/ico_weapon.svg"
          alt="Police Handgun"
          width="150"
        />
        <br />
        <h4>NON-LETHAL TOOLS</h4>
        <p>
          From conducted energy devices (CEDs) to non-lethal ammunition types,
          there are a broad range of
          <strong>new and promising technologie</strong>s which would allow law
          enforcement to "keep the peace" while reducing or eliminating the
          senseless loss of life.
        </p>
        <p>
          <a href="/non-lethal-alternatives" class="btn btn-outline"
            >Read More</a
          >
        </p>
      </div>
      <div class="col">
        <img
          src="@/assets/images/svg/ico_police.svg"
          alt="Police badge"
          height="85"
        />
        <br />
        <h4>LAW ENFORCEMENT</h4>
        <p>
          Law enforcement isn't easy. It is dangerous and difficult work on the
          best of days. However, when the police attempt to counter violence
          with lethal force there can be only one result: the inevitable loss of
          human life.
          <strong>We believe that law enforcement can do better</strong>.
        </p>
        <p>
          <a href="/law-enforcement-loss-of-life" class="btn btn-outline"
            >Read More</a
          >
        </p>
      </div>
      <div class="col">
        <img
          src="@/assets/images/svg/ico_community.svg"
          alt="Shaking hands"
          width="150"
        />
        <br />
        <h4>COMMUNITY</h4>
        <p>
          Our goal is simple: we will research, develop, test and mandate the
          use of an effective non-lethal weapon for America’s police.
          <strong>However, we can't do it alone.</strong> Please join our
          growing community and help us to find a truly effective non-lethal
          technology.
        </p>
        <p>
          <a href="/about-non-lethal-group#support" class="btn btn-outline"
            >Read More</a
          >
        </p>
      </div>
    </section>

    <!-- <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />-->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import WaPoChart from "@/components/charts/WaPoChart.vue";
import EncyclopediaCarousel from "@/components/EncyclopediaCarousel.vue";
import rawData from "@/assets/fatal-police-shootings-data.json";
export default {
  name: "Home",
  components: {
    WaPoChart,
    EncyclopediaCarousel
  },
  data: function() {
    return {
      observer: null,
      intersected: false,
      observerOptions: {
        root: null,
        rootMargin: "0px",
        threshold: 0.9
      },
      rawData: [],
      analyticsData: [],
      chartYears: [],
      races: [
        { label: "Asian", value: "A" },
        { label: "Black", value: "B" },
        { label: "Hispanic", value: "H" },
        { label: "White", value: "W" }
      ],
      genders: [
        { label: "Male", value: "M" },
        { label: "Female", value: "F" }
      ],
      wapoRace: "",
      wapoGender: ""
    };
  },
  computed: {
    chartData: function() {
      return {
        labels: this.chartYears,
        datasets: [
          {
            data: this.getDataValues(),
            backgroundColor: "#d0021b"
          }
        ]
      };
    }
  },
  mounted: function() {
    let vm = this;
    let uiElements = document.getElementsByClassName("ui-style");
    this.observer = new IntersectionObserver(entries => {
      const target = entries[0];
      if (target.isIntersecting) {
        this.intersected = true;
        // style UI elements so they stand out against background
        for (let i = 0; i < uiElements.length; i++) {
          uiElements[i].classList.remove("bg-white");
          uiElements[i].classList.remove("bg-red");
        }
        // console.log(target.target.id);
        switch (target.target.id) {
          case "section_three":
            for (let i = 0; i < uiElements.length; i++) {
              uiElements[i].classList.add("bg-red");
            }
            break;
          case "section_four":
            for (let i = 0; i < uiElements.length; i++) {
              uiElements[i].classList.add("bg-white");
            }
            break;
        }

        // el.classList.add("fadeIn");
      }
      // else {
      //   el.classList.remove("fadeIn");
      // }
    }, this.observerOptions);

    // targets to observe:
    // let sections = document.getElementsByClassName("sections");
    // console.log(sections);
    // sections.forEach(section => {
    //   this.observer.observe(section);
    // });
    let observeElements = document.getElementsByClassName("section");
    for (let i = 0; i <= uiElements.length + 1; i++) {
      // console.log(observeElements[i]);
      this.observer.observe(observeElements[i]);
    }

    // load police killings data json data
    vm.rawData = rawData;
    // run analytics
    vm.getAnalytics();
  },
  methods: {
    getAnalytics: function() {
      // copy rawData
      let data = this.rawData;
      // filter on race
      if (this.wapoRace) {
        data = data.filter(d => d.race === this.wapoRace);
      }
      // filter on gender
      if (this.wapoGender) {
        data = data.filter(d => d.gender === this.wapoGender);
      }
      // save filtered results
      this.analyticsData = data;
      // update chart
      this.getDataLabels();
    },
    getDataLabels: function() {
      let years = [];
      this.analyticsData.forEach(u => {
        years.push(u.date.substring(0, 4));
      });
      let unique = years.filter((v, i, a) => a.indexOf(v) === i);
      this.chartYears = unique;
    },
    getDataValues: function() {
      let years = this.chartYears;
      let data = this.analyticsData;
      let values = [];
      years.forEach(y => {
        // let counter = 0
        let v = data.filter(d => d.date.substring(0, 4) === y);
        values.push(v.length);
      });

      return values;
    }
  },
  watch: {
    /* eslint-disable */
    wapoRace(newRace, oldRace) {
      this.getAnalytics();
    },
    wapoGender(newGender, oldGender) {
      this.getAnalytics();
    }
    /* eslint-enable */
  }
};
</script>

<style lang="scss">
.home {
  section {
    min-height: 100vh;
    width: 100%;
    position: relative;
  }
  h2 {
    color: #fff;
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: calc(43px + 1.5vw);
    text-align: left;
    margin: 0;
    // z-index: 100;
    transition: opacity 0.5s ease;
    transition-delay: 0.5s;
    span {
      font-family: "Montserrat", sans-serif;
      font-weight: 900;
    }
  }
  h2.menu-opened {
    z-index: 1;
    opacity: 0;
  }
  h2 a {
    color: #fff;
  }
  #introduction {
    background: url(../assets/images/police-militarism.jpg);
    background-size: cover;
  }
  #blunt_force {
    background: url(../assets/images/etienne-girardet-AOx4f5fjXTc-unsplash.jpg);
    background-size: cover;
    background-position: center;
  }
  #section_two {
    color: #fff;
    background: #1a1a1a;
  }
  #encyclopedia_carousel {
    color: #fff;
    background: #1a1a1a;
    padding-bottom: 30px;
    .intro-link {
      img {
        height: 100px;
      }
      display: block;
      padding-top: 100px;
      color: #fff;
      padding-bottom: 10px;
      text-align: center;
      font-weight: bold;
    }
    p {
      padding-bottom: 100px;
      max-width: 500px;
      margin: 0 auto;
    }
  }
  #section_three {
    background: #d0021b;
    .handcuffs {
      width: 600px;
      height: 500px;
      margin-top: 60px;
    }
  }
  #section_four {
    color: #fff;
    background-color: #1a1a1a;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    h4 {
      margin: 15px 0;
    }
    .btn {
      color: #fff;
    }
    .btn-outline {
      border: 1px solid white;
      margin-top: 30px;
    }
  }
  #section_four .col {
    width: 300px;
  }
  h3.headline {
    color: #fff;
    margin: 0;
    padding: 0;
    padding-top: 200px;
    width: 60vw;
    margin: 0 auto;
    span {
      display: block;
      // text-align: right;
      font-size: 14px;
    }
  }
  #cd-vertical-nav {
    position: fixed;
    right: 40px;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 100;
    transition: opacity 0.5s ease;
    transition-delay: 0.5s;
    ul {
      list-style-type: none;
    }
  }
  #cd-vertical-nav.menu-opened {
    opacity: 0;
  }
  #cd-vertical-nav li {
    text-align: right;
  }
  #cd-vertical-nav a {
    display: inline-block;
    /* prevent weird movements on hover when you use a CSS3 transformation - webkit browsers */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  #cd-vertical-nav a:after {
    content: "";
    display: table;
    clear: both;
  }
  #cd-vertical-nav a span {
    float: right;
    display: inline-block;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
    transform: scale(0.6);
  }
  #cd-vertical-nav a:hover span {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  #cd-vertical-nav a:hover .cd-label {
    opacity: 1;
  }
  #cd-vertical-nav a.is-selected .cd-dot {
    background-color: white;
  }
  #cd-vertical-nav .cd-dot {
    position: relative;
    /* we set a top value in order to align the dot with the label. If you change label's font, you may need to change this top value*/
    top: 8px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: -webkit-transform 0.2s, background-color 0.5s;
    -moz-transition: -moz-transform 0.2s, background-color 0.5s;
    transition: transform 0.2s, background-color 0.5s;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  #cd-vertical-nav.bg-white .cd-dot {
    background-color: #000;
  }
  #cd-vertical-nav .cd-label {
    position: relative;
    margin-right: 10px;
    padding: 0.4em 0.5em;
    color: white;
    font-size: 14px;
    font-size: 0.875rem;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  #cd-vertical-nav.bg-white .cd-label {
    color: #000;
  }
  .chart a,
  .chart select {
    color: #fff;
  }
  .chart label {
    margin: 15px;
  }
}

@media screen and (max-width: 900px) {
  .home {
    #logo {
      font-size: 18px;
      top: 24px;
    }
    h2 {
      font-size: calc(23px + 1.5vw);
    }
    .chart {
      display: none;
    }
    #section_four {
      img {
        margin-top: 60px;
      }
    }
    #section_three .handcuffs {
      width: 300px;
      height: 200px;
    }
  }
}
</style>
