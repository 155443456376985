<template>
  <div>
    <div class="chartWrapper">
      <bar-chart
        :chart-data="btData"
        :options="chartOptions"
        :height="chartHeight"
        :styles="chartWrapperStyles"
      ></bar-chart>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/charts/BarChart.js";
export default {
  name: "wapo-chart",
  props: {
    btData: {
      type: Object,
      default: null
    }
  },
  data: function() {
    return {
      chartHeight: 400,
      chartWrapperStyles: {
        padding: "10px"
      },
      unitTypes: [],
      unitTypesCounter: [],
      datacollection: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "bottom",
          labels: {
            boxWidth: 10,
            usePointStyle: true,
            borderWidth: 0
          }
        },
        scales: {
          xAxes: [
            {
              stacked: false,
              // barPercentage: 1,
              // categoryPercentage: 0.5
            }
          ],
          yAxes: [
            {
              stacked: false,
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },
      colors: ["#dd0031", "#ffa21b", "#fff"]
    };
  },
  components: {
    BarChart
  },
  computed: {},
  watch: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.chartWrapper {
  // height: 500px;
  width: 70vw;
  margin: 0 auto;
  display: inline-block;
  margin-top: 60px;
}
</style>