<template>
  <div class="footer">

    <div class="containerz">
        <div class="colz recent-headlines" style="text-align: left; padding: 15px; margin-left: 15px;">
          <h4>RECENT HEADLINES</h4>
        </div>
    </div>

      <div class="container">
        <div class="content-1-colz">
      <div class="colz">
        <!-- <ul>
                <li v-for="article in news" v-bind:key="article.title">{{ article.title }} <router-link :to="{ name: 'NonLethalNewsArticle', params: { title: article.title }}">{{ article.title }}</router-link> </li>
            </ul> -->
        <div class="card-wrapper">
          <div class="card" v-for="article in recentNews" v-bind:key="article.title">
            <router-link
              :to="{
                name: 'NonLethalNewsArticle',
                params: { title: article.title },
              }"
            >
              <div
                class="image-wrapper"
                :style="{
                  backgroundImage: `url(${article.image})`,
                  backgroundSize: 'cover',
                }"
              >
                <div class="dateRibbon" v-html="parseDate(article.date)"></div>
              </div>

              <div class="caption-wrapper">
                <div class="source">{{ article.source }}</div>
                <div class="title">{{ article.title }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
      </div>



      <div class="container">
       <div class="col">
           <router-link to="/"><h4 class="logo">
      NON
      <span>LETHAL</span>GROUP
    </h4></router-link>
    <p>We are exploring effective non-lethal<br>alternatives for law enforcement.</p>
       </div>
       <div class="col">
           <h4>RESOURCES</h4>
           <p>
               <ul>
                   <li>
                       <router-link to="/encyclopedia-non-lethal-technologies">ENCYCLOPEDIA OF NON LETHAL<br>TECHNOLOGIES</router-link>
                   </li>
               </ul>
           </p>
       </div>
       <div class="col">
           <h4>HOW YOU CAN HELP</h4>
           <p>
               <ul>
                   <li>
                       <router-link to="/about-non-lethal-group#support">Support</router-link>
                   </li>
               </ul>
           </p>
       </div>
       <div class="col">
           <h4>SPREAD THE WORD</h4>
           <p>
              Please join our community: share your thoughts, stories and hopes with us. We are stronger when we are working together. <br> Let your friends know about The Non Lethal Group:</p>
          <vue-goodshare-facebook :page_url="referralUrl" has_icon></vue-goodshare-facebook>
          <vue-goodshare-twitter :page_url="referralUrl" has_icon></vue-goodshare-twitter>
          <vue-goodshare-reddit :page_url="referralUrl" has_icon></vue-goodshare-reddit>
          <vue-goodshare-linked-in :page_url="referralUrl" has_icon></vue-goodshare-linked-in>
          <vue-goodshare-email :page_url="referralUrl" has_icon style="background-color: #dd0031;"></vue-goodshare-email>
       </div>
      </div>
   
  </div>
</template>

<script>
import news from "@/assets/news.json";
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
import VueGoodshareReddit from "vue-goodshare/src/providers/Reddit.vue";
import VueGoodshareLinkedIn from "vue-goodshare/src/providers/LinkedIn.vue";
import VueGoodshareEmail from "vue-goodshare/src/providers/Email.vue";
export default {
  name: "Footer",
  props: {
    // msg: String
  },
  data: function () {
    return {
      news: [],
      referralUrl: "https://nonlethal.group",
    };
  },
  computed: {
    currentPageTitle() {
      let title = this.$route.params.title;
      if (title) {
        return title;
      }
      return "";
    },
    recentNews() {
      let recentArticles = [];
      let n = this.news;
      n.forEach((n) => {
        let article = {
          content: n.content,
          sortdate: new Date(n.date),
          date: n.date,
          image: n.image,
          link: n.link,
          source: n.source,
          summary: n.summary,
          title: n.title,
        };
        recentArticles.push(article);
      });
      let sortedArticles = recentArticles
        .slice()
        .sort((a, b) => b.sortdate - a.sortdate);

      // remove current URL from list of articles
      sortedArticles = sortedArticles.filter(
        (a) => a.title !== this.currentPageTitle
      );

      // remove all but the most recent 4 articles
      sortedArticles.splice(3, sortedArticles.length);

      return sortedArticles;
    },
  },
  components: {
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    VueGoodshareReddit,
    VueGoodshareLinkedIn,
    VueGoodshareEmail,
  },
  methods: {
    parseDate(d) {
      // takes the article date and splits it into formatted sections for display in ribbon
      let datesegments = d.split(" ");
      return `<strong>${datesegments[1]}</strong><span>${datesegments[0]}</span><span>${datesegments[2]}</span>`;
    },
  },
  mounted: function () {
    // load external news file
    this.news = news;
  },
};
</script>

<style scoped lang="scss">
.footer {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #000;
  color: #fff;
  .container {
    display: flex;
    justify-content: center;
    align-items: top;
    flex-wrap: wrap;
  }
  .col {
    text-align: left;
    min-width: 300px;
    max-width: 400px;
    // border: 1px solid #fff;
    padding: 15px;
  }
  .logo {
    display: flex;
    //   position: fixed;
    //   top: 20px;
    //   left: 20px;
    padding: 0px;
    margin: 0px;
    color: #fff;
    white-space: nowrap;
    font-weight: bolder;
    z-index: 100;
    transition: all 0.5s ease;
    span {
      color: #d0021b;
      transition: all 0.5s ease;
    }
  }
  ul {
    list-style: none;
    li {
      margin-bottom: 15px;
    }
  }
  a {
    border: none !important;
    text-decoration: none;
    color: #fff;
  }
  p {
    padding-top: 40px;
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .content-1-colz {
    width: 90%;
    .caption-wrapper .title {
      font-size: 20px;
      width: 93%;
    }
  }
}

</style>
