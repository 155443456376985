<template>
  <div class="encyclopediaCarousel">
    <carousel
      :autoplay="false"
      :navigationEnabled="false"
      :scrollPerPage="true"
      :perPage="perPage"
      paginationActiveColor="red"
    >
      <slide id="slide3">
        <router-link
          to="/encyclopedia-non-lethal-technologies/chemical-irritants"
          class="slide-link"
        >
          <h3>
            Chemical Irritants
            <!-- , Once Favored By Law Enforcement, Have Become Increasingly Controversial -->
          </h3>
        </router-link>
      </slide>
      <slide id="slide4">
        <router-link
          to="/encyclopedia-non-lethal-technologies/conducted-energy-devices"
          class="slide-link"
        >
          <h3>
            <!-- Understanding the Effectiveness of  -->
            Conducted Energy Device
          </h3>
        </router-link>
      </slide>
      <slide id="slide1">
        <router-link
          to="/encyclopedia-non-lethal-technologies/barriers"
          class="slide-link"
        >
          <h3>
            Barriers
            <!-- Employ a Variety of Techniques to Control Crowds and Safely -->
            <!-- Apprehend Subjects -->
          </h3>
        </router-link>
      </slide>
      <slide id="slide2">
        <router-link to="/encyclopedia-non-lethal-technologies/blunt-force"
        class="slide-link" >
          <h3>
            Blunt Force
            <!-- Weapons Have a Long History but Are Facing Renewed Scrutiny -->
          </h3>
        </router-link>
      </slide>
      <slide id="slide5">
          <router-link to="/encyclopedia-non-lethal-technologies/directed-energy-devices"
          class="slide-link" >
        <h3>
          Directed Energy Devices
          <!-- Represent An Innovative Approach to Non-Lethal Weapons -->
        </h3>
          </router-link>
      </slide>
      <slide id="slide6">
          <router-link to="/encyclopedia-non-lethal-technologies/distraction-devices"
          class="slide-link" >
        <h3>
          <!-- Non-Lethal  -->
          Distraction Weapons
          <!-- Use Light and Sound to Disorient Suspects -->
        </h3>
          </router-link>
      </slide>
      <slide id="slide7">
          <router-link to="/encyclopedia-non-lethal-technologies/psychoactive-chemicals"
          class="slide-link" >
        <h3>
          Psychoactive Chemicals
          <!-- Could Help Defuse Violent, One-on-One Interactions -->
        </h3>
          </router-link>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "EncyclopediaCarousel",
  props: {
    msg: String
  },
  //   data: function() {
  //     //   return {
  //     //       currentTitle: this.$router.currentRoute.name
  //     //   }
  //   },
  components: {
    Carousel,
    Slide
  },
  computed: {
      perPage() {
          let w = window.innerWidth;
          return w >= 900 ? 2 : 1;
        // return 2;
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.VueCarousel-slide {
  position: relative;
  transition: all 0.5s ease;
  height: 70vh;
  width: 33vw;
  &:hover {
      transform: scale(1.05);
      h3 {
          bottom: 60px;
          left:40px;
      }
  }
}
.VueCarousel-navigation .VueCarousel-navigation-prev {
  color: white !important;
  left: 30px !important;
}
.VueCarousel-navigation .VueCarousel-navigation-next {
  color: white !important;
  right: 30px !important;
}
h3 {
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: #fff;
  font-size: calc(28px + 1.5vw);
  line-height: calc(28px + 1.5vw);
  text-align: left;
  width: 46vw;
  transition: all 0.5s ease;
}
#slide1 {
  background: url(../assets/images/trinity-nguyen-p13uJgZsyi4-unsplash.jpg);
  background-size: cover;
//   height: 70vh;
//   width: 33vw;
}
#slide2 {
  background: url(../assets/images/etienne-girardet-AOx4f5fjXTc-unsplash.jpg);
  background-size: cover;
//   height: 70vh;
//   width: 33vw;
}
#slide3 {
  background: url(../assets/images/ev-1oPsQnyQslE-unsplash.jpg);
  background-size: cover;
//   height: 70vh;
//   width: 33vw;
}
#slide4 {
  background: url(../assets/images/taser.jpg);
  background-size: cover;
//   height: 70vh;
//   width: 33vw;
  h3 {
    color: #1a1a1a;
  }
}
#slide5 {
  background: url(../assets/images/jr-korpa-4DykvhW8SsI-unsplash.jpg);
  background-size: cover;
//   height: 70vh;
//   width: 33vw;
}
#slide6 {
  background: url(../assets/images/lrad.jpg);
  background-position: bottom right;
  background-size: cover;
//   height: 70vh;
//   width: 33vw;
}
#slide7 {
  background: url(../assets/images/TranquilizerDart.jpg);
  background-size: cover;
//   height: 70vh;
//   width: 33vw;
  h3 {
    color: #1a1a1a;
  }
}
.slide-link {
  height: 100%;
  width: 100%;
  // border: 1px solid red;
  display: block;
}

// mobile overrides
@media screen and (max-width: 900px) {
    .VueCarousel-slide {
        width: 100vw;
    }
}
</style>
