<template>
  <div class="AboutNonLethalGroup">
    <section id="about">
      <div class="content-1-col">
        <div class="col">
          <h4>ABOUT THE NON LETHAL GROUP</h4>
          <strong>
            Our goal is simple: we will
            <span>research</span>,
            <span>develop</span>,
            <span>test</span> and
            <span>mandate</span> the use of an effective non-lethal weapon for America’s police.
          </strong>
        </div>
      </div>
      <div class="content-2-col">
        <div class="col col-large">
          <div class="content-2-colz shadow letter-container">
            
              <h4 style="margin-left:30px;">Thank You!</h4>

              <p>I look forward to reading your thoughts and ideas.</p>

              <p>Thanks so much for getting in touch. Together we can bring about truly amazing changes.</p>

<div class="signature-wrapper">
  <p>Sincerely,</p>
  <p><img
                  src="/static/images/signature.png"
                  alt="Dr. Mable Jones signature"
                  class="signature"
                /></p>
</div>

          </div>

          <div class="" style="text-align: center; padding-bottom: 60px; margin-top: 60px;">
            <h3>"Families should not have to present their lost loved ones, who are victims of police violence, as virtuous, exemplary people 'Of Promise', but only as human beings worthy of surviving an encounter with a bad cop".</h3>
            <!-- <h5>Prince Jones, Jr</h5> -->
          </div>
        </div>
        <div class="col col-small shadow our-approach">
          <h4>Our Approach</h4>
          <div class="cell">
            <img src="@/assets/images/ico_research.png" alt="Research" />
            <h5>Research</h5>
            <p>An honest review of the most promising technologies</p>
          </div>
          <div class="cell">
            <img src="@/assets/images/ico_develop.png" alt="Develop" />
            <h5>Develop</h5>
            <p>Select, refine and expand upon a winning technology</p>
          </div>
          <div class="cell">
            <img src="@/assets/images/ico_test.png" alt="Test" />
            <h5>Test</h5>
            <p>Ensure the safety and effectiveness of the selected technology</p>
          </div>
          <div class="cell">
            <img src="@/assets/images/ico_mandate.png" alt="Mandate" />
            <h5>Mandate</h5>
            <p>Require law enforcement to use the proven and tested non-lethal alternative</p>
          </div>
          <p></p>
        </div>
      </div>
    </section>
    <section id="support">
      <div class="content-1-col">
        <div class="col">
          <h4>HOW YOU CAN HELP</h4>
          <p><strong>
          We are just getting started, but already we've found many <span>promising alternatives</span> to our increasingly <span>lethal</span> and <span>militaristic police force</span>. 
          </strong></p>
          <p>We believe that there must be a better way. Law enforcement shouldn't look to a lethal weapon as their primary means of "keeping the peace". We also believe that we can't solve this crisis on our own. Please join our community: share your thoughts, stories and hopes with us. We are stronger when we are working together.</p>
          <p
            class="text-center"
          >Let your friends know about The Non Lethal Group:</p>
          <vue-goodshare-facebook :page_url="referralUrl" has_icon></vue-goodshare-facebook>
          <vue-goodshare-twitter :page_url="referralUrl" has_icon></vue-goodshare-twitter>
          <vue-goodshare-reddit :page_url="referralUrl" has_icon></vue-goodshare-reddit>
          <vue-goodshare-linked-in :page_url="referralUrl" has_icon></vue-goodshare-linked-in>
          <vue-goodshare-email :page_url="referralUrl" has_icon style="background-color: #dd0031;"></vue-goodshare-email>
        </div>

        <div class="col" style="margin-top: 60px;">
          <h4>GET INVOLVED</h4>
          <p>We would love to hear your thoughts and ideas. Are you a member of the law enforcement community? Do you have experience with non-lethal technologies? We would love to hear from you!</p>
<form
  action="https://formspree.io/f/mlezgpra"
  method="POST"
  class="contact"
>
<p>
    <label><span>Your Name: </span><input type="text" name="name" /></label>   
  </p>
  <label>
    <span>Your emaill:</span>
    <input type="email" name="_replyto">
  </label>
  <label>
    <span>Your message:</span>
    <textarea name="message"></textarea>
  </label>
  <!-- your other form fields go here -->
  <button type="submit" class="btn btn-send">Send</button>
</form>
        </div>
        <div class="col" style="margin-top: 60px;">
          <h4>FINANCIAL SUPPORT</h4>
          <p><strong>
          The Non Lethal Group is working to build an active and engaged community.</strong> One of our goals is to identify the most promising areas of research and development focused on non lethal technologies, and to support them financially. We would be grateful if you'd be willing to join us in this support. We are a public charity, operating under the name of the <strong>Prince Jones Jr Foundation, Inc.</strong> Your donation is tax deductable.
          </p>
          <p><table class="donation-table">
    <tr>
        <td><a href="https://buy.stripe.com/eVaaI3a5T6LW7EA5kk" class="btn btn-donate">$25 Donation</a></td>
        <td><a href="https://buy.stripe.com/cN2eYj2Drgmw2kg145" class="btn btn-donate">$50 Donation</a></td>
    </tr>
    <tr>
        <td><a href="https://buy.stripe.com/28o5nJdi50ny0c8dQS" class="btn btn-donate">$100 Donation</a></td>
        <td><a href="https://buy.stripe.com/7sI17tce1dak7EA8wz" class="btn btn-donate">$500 Donation</a></td>
    </tr>
</table></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
import VueGoodshareReddit from "vue-goodshare/src/providers/Reddit.vue";
import VueGoodshareLinkedIn from "vue-goodshare/src/providers/LinkedIn.vue";
import VueGoodshareEmail from "vue-goodshare/src/providers/Email.vue";
export default {
  name: "ThankYou",
  data: function () {
    return {
      image: "./assets/images/friendly-law-enforcement.jpg",
      publicPath: process.env.BASE_URL,
      referralUrl: "https://nonlethal.group",
    };
  },
  components: {
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    VueGoodshareReddit,
    VueGoodshareLinkedIn,
    VueGoodshareEmail,
  },
};
</script>

<style lang="scss">
.btn {
  padding: 15px 30px;
  color: #fff;
  margin: 5px;
  text-align: center;
  font-weight: 600;
}
.btn-donate, .btn-send {
  background-color: #dd0130;
  transition: all 0.5s ease;
  &:hover {
    background-color: darken(#dd0130, 10%);
  }
}
.donation-table {
  width: 100%;
  max-width: 800px;
}
.intro {
  text-align: left;
  justify-content: left;
  width: 90%;
  margin: 0 auto;
}
.letter-container {
  display: block;
  padding-top: 30px;
  p {
    margin: 20px;
  }
}
.signature-wrapper {
  display: flex;
  min-width: 100%;
  justify-content: flex-end;
  margin-right: 100px;
}
.photo {
  max-width: 250px;
  border: 7px solid #fff;
  box-shadow: 10px 10px 16px -4px rgba(0, 0, 0, 0.45);
}
.photo-right {
  float: right;
  transform: rotate(-5deg);
  margin-left: 40px;
}
.photo-left {
  float: left;
  transform: rotate(5deg);
  margin-right: 40px;
}
.AboutNonLethalGroup .our-approach {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  .cell {
    h5 {
      color: #d0021b;
      margin: 0;
    }
    p {
      margin: 0 10px;
    }
    img {
      width: 35px;
    }
  }
  h4 {
    margin-top: 30px;
    font-size: 22px;
  }
}

#support {
  padding-top: 20px;
  padding-bottom: 90px;
  background-color: #1a1a1a;
  h4,
  p {
    color: #fff;
  }
}

.contact {
  padding-top: 20px;
  span {
    width: 90px;
    display: inline-block;
    text-align: right;
    padding-right: 5px;
  }
  input[type="text"], input[type="email"] {
    border: 1px solid #fff;
    padding: 5px;
    margin-bottom: 10px;
    width: 300px;
    color: #fff;
  }
  textarea {
    width: 300px;
    border: 1px solid #fff;
    padding: 5px;
    color: #fff;
  }
  .btn-send {
    margin-top: 10px;
    width: 403px;
  }
}

@media screen and (max-width: 900px) {
  .signature {
    float: none;
    margin: 0 auto;
    display: block;
  }
  .AboutNonLethalGroup .our-approach {
    .cell {
      img {
        margin-top: 30px;
      }
    }
  }
}
</style>