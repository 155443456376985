<template>
  <div id="app">
    <router-link to="/"
      ><h1 id="logo" class="ui-style">
        NON
        <span>LETHAL</span>GROUP
      </h1></router-link
    >

    <div class="header">
      <span class="burger-title">MENU</span>
      <div class="burger-container">
        <div id="burger" class="ui-style">
          <div class="bar topBar"></div>
          <div class="bar btmBar"></div>
        </div>
      </div>
      <div class="menu-container">
        <div id="menu_bg"></div>
        <ul class="menu">
          <li class="menu-item">
            <router-link to="/">HOME</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/non-lethal-news">HEADLINES</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/law-enforcement-loss-of-life"
              >LAW ENFORCEMENT AND THE LOSS OF LIFE</router-link
            >
          </li>
          <li class="menu-item">
            <router-link to="/non-lethal-alternatives"
              >AN EXPLORATION OF NON LETHAL ALTERNATIVES</router-link
            >
          </li>
          <li class="menu-item">
            <router-link to="/encyclopedia-non-lethal-technologies"
              >ENCYCLOPEDIA</router-link
            >
          </li>
          <li class="menu-item">
            <router-link to="/about-non-lethal-group"
              >OUR APPROACH &amp; OUR GOALS</router-link
            >
          </li>
          <li class="menu-item">
            <router-link to="/about-non-lethal-group#support"
              >HOW YOU CAN HELP</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Footer
  },
  data: function() {
    return {
      styleObject: {
        bamboo: true
      }
      // index: 0
    };
  },
  mounted: function() {
    var burger = document.querySelector(".burger-container");
    var header = document.querySelector(".header");
    var sideNav = document.querySelector("#cd-vertical-nav");
    var headlines = document.getElementsByTagName("h2");

    burger.onclick = function() {
      header.classList.toggle("menu-opened");
      if(sideNav){
      sideNav.classList.toggle("menu-opened");
      }
      for (let headline of headlines) {
        headline.classList.toggle("menu-opened");
      }
      // let menuContainer = document.querySelector(".menu-container");
      // menuContainer.style.position = "inherit";
      // menuContainer.style.right = "initial";
      // menuContainer.style.width = "100vw";
    };
  },
  watch: {
    /* eslint-disable */
    $route(to, from) {
      //close mobile nav menu on page load, unless its initial page view. Remove menu-opened status from elements
      let header = document.querySelector(".header");
      // let sideNav = document.querySelector("#cd-vertical-nav");
      // var headlines = document.getElementsByTagName("h2");
      header.classList.remove("menu-opened");
      // move menu container offscreen
      // let menuContainer = document.querySelector(".menu-container");
      // menuContainer.style.position = "absolute";
      // menuContainer.style.right = "-100vw";
      // position: absolute;
      // right: -100vw;

      // sideNav.classList.remove("menu-opened");
      // for (let headline of headlines) {
      //   headline.classList.remove("menu-opened");
      // }
      // window.scrollTo(0, 0);
      // style UI elements as needed if light or dark background
      // console.log(to.name);
      var uiElements = document.getElementsByClassName("ui-style");
      switch (to.name) {
        case "Home":
          // let sideNav = document.querySelector("#cd-vertical-nav");
          // sideNav.classList.remove("menu-opened");
          // let headlines = document.getElementsByTagName("h2");
          // for (let headline of headlines) {
          //   headline.classList.remove("menu-opened");
          // }
          // for (let i = 0; i < uiElements.length; i++) {
          //   uiElements[i].classList.remove("bg-white");
          // }
          break;
          case "NonLethalNews":
          case "NonLethalNewsArticle":
            break;
        default:
          // let uiElements = document.getElementsByClassName("ui-style");
          for (let i = 0; i < uiElements.length; i++) {
            uiElements[i].classList.add("bg-white");
          }
          break;
      }
    }
  }
};
</script>

<style lang="scss">
* {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        // font-size: 100%;
        vertical-align: baseline;
        background: transparent;
    }
html,
body {
  background-color: #fafafa;
  color: #888;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

// ::-webkit-scrollbar {
//   display: none;
// }
// html {
//   scrollbar-width: none; /* For Firefox */
//   -ms-overflow-style: none; /* For Internet Explorer and Edge */
// }

// html::-webkit-scrollbar {
//   width: 0px; /* For Chrome, Safari, and Opera */
// }

$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
$menuItems: 7;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#logo {
  display: flex;
  position: fixed;
  top: 20px;
  left: 20px;
  padding: 0px;
  margin: 0px;
  color: #fff;
  white-space: nowrap;
  font-weight: bolder;
  z-index: 100;
  transition: all 0.5s ease;
  span {
    color: #d0021b;
    transition: all 0.5s ease;
  }
}
#logo.bg-red {
  span {
    color: #2c3e50;
  }
}
#logo.bg-white {
  color: #2c3e50;
  span {
    color: #d0021b;
  }
}

.content-1-col {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
  align-items: top;
  flex-wrap: wrap;
  .col {
    width: 100%;
    padding: 0 25px;
    text-align: left;
    min-width: 300px;
  }
  .img-full {
    width: 100%;
  }
  h4 {
    font-size: calc(28px + 1.5vw);
    margin: 0;
  }
  strong span {
    color: #d0021b;
  }
}

.content-3-col {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
  align-items: top;
  flex-wrap: wrap;
  .col {
    width: 29%;
    text-align: left;
    min-width: 300px;
  }
  .img-full {
    width: 100%;
  }
  h4 {
    font-size: calc(28px + 1.5vw);
    margin: 0;
  }
  strong span {
    color: #d0021b;
  }
}
.content-2-col {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-around;
  align-items: top;
  flex-wrap: wrap;
  .col {
    width: 50%;
    text-align: left;
    min-width: 300px;
  }
  .col-large {
    width: 69%
  }
  .col-small {
    width: 19%
  }
  .img-full {
    width: 100%;
  }
  h4 {
    font-size: calc(28px + 1.5vw);
    margin: 0;
  }
  strong span {
    color: #d0021b;
  }
}
.magazine {
  margin-top: 100px;
  margin-left: 25px;
  margin-right: 25px;
  columns: 3 200px;
  column-gap: 4em;
  p {
    text-align: left;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .img-full {
    width: 100%;
  }
  h4 {
    font-size: calc(28px + 1.5vw);
    margin: 0;
    text-align: left;
    text-transform: uppercase;
  }
  strong span {
    color: #d0021b;
  }
  h5, .p3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: left;
    margin-top: 30px;
  }
  h6 {
    font-size: 16px;
    margin: 0;
    text-align: left;
    margin-top: 30px;
  }
  sup {
    font-size: 11px;
    position: relative;
    top: -5px;
  }
  a {
    border-bottom: 3px solid #eeb0b7;
    box-shadow: inset 0 -5px 0 #eeb0b7;
    color: inherit;
    transition: all .25s cubic-bezier(.33,.66,.66,1);
  text-decoration: none;
}

a:hover {
    background: #eeb0b7;
}
}
.news {
  position: relative;
  background-color: #252525;
  color: #fff;
  min-height: 100vh;
  padding-top: 100px;
}
.references {
  background-color: #cac1c1;
  padding: 60px 20px !important;
  p {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: bold;
  }
}
.credits {
  padding: 60px 20px !important;
  p {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: bold;
  }
}
// .content-1-col {
//   text-align: left;
//   padding: 20px;
// }
.shadow {
  background-color: #fff;
  box-shadow: 0 5px 15px -5px rgba(0,0,0,.1);
}
.letter {
  // padding: 20px;
}

.header {
  z-index: 100;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  .burger-title {
    display: none;
    //   color: #fff;
    //   position: fixed;
    //   right: 65px;
    // top: 23px;
  }
  .burger-container {
    z-index: 100;
    position: fixed;
    right: 10px;
    top: 10px;
    display: inline-block;
    height: 50px;
    width: 50px;
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 0.3s $cubic;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    background: #d0021b;
    border-radius: 30px;
    
    #burger {
      width: 18px;
      height: 8px;
      position: relative;
      display: block;
      margin: -4px auto 0;
      top: 50%;
      pointer-events: all;
      .bar {
        width: 100%;
        height: 1px;
        display: block;
        position: relative;
        background: #fff;
        transition: all 0.3s $cubic;
        transition-delay: 0s;
        &.topBar {
          transform: translateY(0px) rotate(0deg);
        }
        &.btmBar {
          transform: translateY(6px) rotate(0deg);
        }
      }
    }
    #burger.bg-white .bar {
      background: #2c3e50;
    }
  }
  .menu-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    pointer-events: none;
    // position: absolute;
    // right: -100vw;
    // width: 0;
  }
  a.router-link-exact-active {
    color: #000 !important;
  }
  ul.menu {
    z-index: 1;
    position: relative;
    display: block;
    // padding: 150px 48px 0;
    padding: 0;
    list-style: none;
    pointer-events: none;
    li.menu-item {
      // border-bottom: 1px solid #333;
      margin-top: 5px;
      transform: scale(1.15) translateX(30px);
      opacity: 0;
      transition: transform 0.5s $cubic, opacity 0.6s $cubic;
      @for $i from 1 through $menuItems {
        &:nth-child(#{$i}) {
          transition-delay: #{0.56 - ($i * 0.07)}s;
        }
      }
      a {
        display: block;
        position: relative;
        color: #fff;
        // font-family: $mukta;
        font-weight: 100;
        text-decoration: none;
        font-size: calc(23px + 1.5vw);
        // line-height: 80px;
        font-weight: 200;
        width: 100%;
        margin-bottom: 60px;
      }
    }
  }
  #menu_bg {
    background-color: #d0021b;
    transition: all 0.5s ease;
    transition-delay: 0.5s;
    position: absolute;
    top: -300px;
    right: -300px;
    width: 0px;
    height: 0px;
    // transform: scale(0);
    // border-radius: 100%;
    border-radius: 100% 0 100% 100%;
  }
  &.menu-opened {
    .burger-container {
      transform: rotate(90deg);
      // span {
      //   display: none;
      // }
      #burger {
        .bar {
          transition: all 0.4s $cubic;
          transition-delay: 0.2s;
          &.topBar {
            transform: translateY(4px) rotate(45deg);
          }
          &.btmBar {
            transform: translateY(3px) rotate(-45deg);
          }
        }
      }
    
    }
    ul.menu {
      pointer-events: all;
      li.menu-item {
        transform: scale(1) translateX(0px);
        opacity: 1;
        @for $i from 1 through $menuItems {
          &:nth-child(#{$i}) {
            transition-delay: #{0.07 * $i + 0.4}s;
          }
        }
      }
    }
    #menu_bg {
      width: 100vw;
      height: 100vh;
      border-radius: 0px;
      top: 0px;
      right: 0px;
    }
    .menu-container {
      height: 100vh;
      // width: 100vw;
      // position: relative;
      // right: inherit;
    }
  }
}

.btn {
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  padding: 15px;
  text-decoration: none;
}
.btn-outline {
  color: #2c3e50;
  border: 1px solid #2c3e50;
}

.breadcrumbs {
  position: relative;
  background-color: #000;
  color: #fff;
  // padding: 6px 3px 6px 20px;
  text-align: left;
  font-size: 23px;
  height: 40px;
  a {
    color: #fff;
  }
  .ico {
    max-height: 30px;
    padding-right: 12px;
    position: relative;
    bottom: -6px;
    padding-left: 20px;
  }
  .submenu {
    padding: 15px 20px 15px;
    color: red;
    opacity: 0;
    transition: all 0.5s ease;
    background-color: rgba(0, 0, 0, .5);
    // width: 100% !important;
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      li a {
        color: #fff;
        font-size: 13px;
        text-decoration: none;
        // border: 1px solid #d0021b;
        // padding: 3px 6px;
      }
    }
    a.router-link-exact-active {
      // font-weight: bold;
      // color: #d0021b;
      // background: rgba(255, 255, 255, .5)
      // text-decoration: underline;
    }
  }
  a.subshow:hover ~ div.submenu {
    opacity: 1;
  }
  div.submenu:hover {
    opacity: 1;
  }
}

.introduction {
  min-height: 500px;
  background-color: #000;
  position: relative;
  h2 {
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: #fff;
    font-size: calc(38px + 1.5vw);
    line-height: calc(38px + 1.5vw);
    margin: 0; 
    padding: 0;
    text-shadow: 2px 2px 7px rgba(0, 0, 0, .5);
  }
}
.credits span {
  // font-style: italic;
}
.main-intro {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(./assets/images/topography.png);
  h3 {
    max-width: 70vw;
  }
}

.encyclopedia-entry {
  margin-top: 0px;
  ul {
    list-style: none;
    text-align: left;
    margin-bottom: 20px;
    font-size: 13px;
    li {
      margin-bottom: 10px;
    }
  }
}

// HEADLINES
.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-width: 100vw;
  // padding-left: 30px;
}
.news-body {
  padding: 30px 0;
}
.card {
  // width: 400px;
  width: 30%;
  display: inline;
  min-height: 540px;
  margin: 0 30px 30px 0;
  box-shadow: 0 19px 38px rgba(#000, 0.3), 0 15px 12px rgba(#000, 0.2);
  .image-wrapper {
    height: 300px;
  }
  
  .caption-wrapper {
    height: 230px;
    padding: 10px;
    background-color: #fff;
    color: gray;
    position: relative;
    .source {
      font-size: 11px;
      margin-bottom: 30px;
    }
    
    .title {
      font-size: 31px;
      // position: absolute;
      // bottom: 0px;
      // margin-bottom: 10px;
    }
  }
  .dateRibbon {
    background-color: #d0021b;
    color: #fff;
    padding: 3px;
    text-align: center;
    width: 40px;
    margin-left: 15px;

    strong {
      display: block;
      font-size: 22px;
      margin-bottom: 0px;
      padding-bottom: 0px;
      text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
    }
    span {
      display: block;
      font-size: 11px;
    }
  }
  a {
    text-decoration: none !important;
    border-bottom: none !important;
    border: none !important;
  }
}

@media screen and (max-width: 900px) {
  .card {
    width: 100%;
    margin-right: 0px;
  }
  .featured-sources {
    min-height: 400px;
  }
  .featured-sources div {
    width: 100%;
    display: block;
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  ul.menu {
    li.menu-item a {
      font-size: 20px !important;
    }
  }
  .content-3-col .col {
    min-width: none;
    width: 100%;
    padding: 15px;
  }
}
</style>
